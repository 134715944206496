import { compact, isEmpty, isNil } from 'lodash';
import {
  CoreListingLineItem,
  Currency,
  ListingState,
  Maybe,
  Listing as PGListingType,
} from '../../types/apollo/generated/types.generated';
import { Filter, GenericFilterOption } from '../../types/filters/filters';
import {
  ListingItemType,
  ListingWithAuthorAndImages,
  Listing as STListingType,
} from '../../types/sharetribe/listing';
import { createResourceLocatorString } from '../routes';
import { findOptionsForSelectFilter } from '../search';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL } from '../types';
import {
  createSlug,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  ListingPageParamType,
} from '../urlHelpers';
import { Product, ShopifyProductVariant } from '../../types/shopify/product';
import { ITradeInCreditPayoutOption } from '../../types/contentful/types.generated';
import { FulfillmentMethod } from '../../types/shopConfig/shopConfigV2';
import { getFulfillmentMethod } from '../helpers';

// Keep in sync with server/types/sharetribe/listing.ts
// All possible listing creation starting points aka "find item" methods.
export enum FindItemMethod {
  // Listing flow entrypoints
  Search = 'search',
  EmailAccount = 'email_account',
  OrderNumber = 'order_number',
  CannotFind = 'cannot_find',
  AllProducts = 'all_products',
  Manual = 'manual', // For backwards compatibility
  ListAnyBrand = 'list_any_brand',

  // Upload and relist flow entrypoints
  Upload = 'upload',
  ApiUpload = 'api_upload',
  TradeInUpload = 'trade_in_upload',
  RelistFromTreet = 'relist_from_treet',
  RelistAsDuplicate = 'relist_as_duplicate', // Available for Brand Direct Only
}

// Find item methods that represent user-facing entrypoints in the listing flow.
export enum ListingFlowFindItemMethod {
  Search = 'search',
  EmailAccount = 'email_account',
  OrderNumber = 'order_number',
  CannotFind = 'cannot_find',
  ListAnyBrand = 'list_any_brand',
  // TODO (TREET-5308) Update all_products to be fully considered a ListingFlowFindItemMethod.
  // Refactor shouldShowOtherItemsInSearch config field and update listing flow config
  // field in contentful.
  AllProducts = 'all_products',
}

export enum PhotoTips {
  NeutralBackground = 'NEUTRAL_BACKGROUND',
  WhiteBackground = 'WHITE_BACKGROUND',
  HangIt = 'HANG_IT',
  HangItOrLayItFlat = 'HANG_IT_OR_LAY_FLAT',
  Clean = 'CLEAN',
  CleanAndIroned = 'CLEAN_AND_IRONED',
  GoodLighting = 'GOOD_LIGHTING',
  CaptureQuirks = 'CAPTURE_QUIRKS',
}

export enum OriginalPriceSource {
  CompareAtPrice = 'Compare At Price',
  RegularPrice = 'Regular Price',
}

export const PHOTO_TIPS_TO_LABEL = {
  [PhotoTips.NeutralBackground]: 'Neutral Background',
  [PhotoTips.WhiteBackground]: 'Select a White Background',
  [PhotoTips.HangIt]: 'Hang It',
  [PhotoTips.HangItOrLayItFlat]: 'Hang It or Lay Flat',
  [PhotoTips.Clean]: 'Clean',
  [PhotoTips.CleanAndIroned]: 'Clean & Ironed',
  [PhotoTips.GoodLighting]: 'Good Lighting',
  [PhotoTips.CaptureQuirks]: 'Capture Quirks',
};

// Get the label for a condition
export const getConditionLabel = (conditionKey: string | undefined, filterConfig: Filter) => {
  if (!conditionKey) return null;
  const conditionOptions = findOptionsForSelectFilter('condition', filterConfig);
  const condition = conditionOptions.find((c: GenericFilterOption) => c.key === conditionKey);
  return condition ? condition.label : condition;
};

export const createListingURL = (routes: any, listing: STListingType) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;

  let variant;
  if (isPendingApproval) {
    variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  }

  const linkProps = isPendingApproval
    ? {
        name: 'ListingPageVariant',
        params: {
          id,
          slug,
          variant,
        },
      }
    : isDraft
    ? {
        name: 'EditListingPage',
        params: {
          id,
          slug,
          type: ListingPageParamType.Draft,
          // This will direct users to their last edit spot in the draft
          tab: 'shipping',
        },
      }
    : {
        name: 'ListingPage',
        params: { id, slug },
      };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Keep in sync with server/utils/listings.ts
const getPayoutValuesForListing = (
  listing: STListingType,
  listingLineItem?: CoreListingLineItem | undefined
): { cash: number; credit: number } => {
  let cash = 0;
  let credit = 0;
  const payoutInfo = listing?.attributes?.publicData?.payoutInfo;
  // Use listing line item price if available (for purchased listings).
  const price = listingLineItem?.price || listing?.attributes?.price?.amount;
  const isTradeInItem =
    listing?.attributes?.publicData?.listingItemType === ListingItemType.TradeIn;
  // Trade-in listings can be $0
  if (isTradeInItem || (payoutInfo && price)) {
    const { cashPayoutPercentage, creditPayoutPercentage, creditPayoutFixedAmount } = payoutInfo;

    if (cashPayoutPercentage >= 0) {
      cash = cashPayoutPercentage * price;
    }

    if (creditPayoutFixedAmount >= 0) {
      credit = creditPayoutFixedAmount;
    } else {
      credit = creditPayoutPercentage * price;
    }
  }

  return { cash, credit };
};

// Keep in sync with server/utils/listings.ts
export const getTotalPayoutValueForListings = (
  listings: STListingType[],
  listingLineItems?: CoreListingLineItem[] | undefined
): { cash: number; credit: number } => {
  if (isEmpty(listings)) return { cash: 0, credit: 0 };

  const { cash, credit } = listings.reduce(
    (acc, listing) => {
      const listingLineItem = listingLineItems?.find(
        (lineItem) => lineItem?.sharetribeListingId === listing.id.uuid
      );
      const listingTotal = getPayoutValuesForListing(listing, listingLineItem);
      acc.cash += listingTotal.cash;
      acc.credit += listingTotal.credit;
      return acc;
    },
    { cash: 0, credit: 0 }
  );

  const maxCreditPerBundle =
    listings[0]?.attributes?.publicData?.payoutInfo?.tradeInCreditPayoutBundleAmount;

  const isTradeInBundle = listings.every(
    (listing) => listing.attributes?.publicData?.listingItemType === ListingItemType.TradeIn
  );

  // For trade-in bundles with a flat amount of credit per bundle,
  // credit issued can be either $0 if all
  // items are disputed, or the full amount.
  const maybeAdjustedCredit =
    maxCreditPerBundle && isTradeInBundle ? Math.min(credit, maxCreditPerBundle) : credit;

  return { cash: Math.round(cash), credit: Math.round(maybeAdjustedCredit) };
};

const getLegacyOriginalShopifyProductPrice = (
  originalPriceSources: OriginalPriceSource[],
  shopifyProduct?: Pick<Product, 'priceRange'>,
  shopifyProductVariant?: Pick<ShopifyProductVariant, 'compareAtPrice' | 'price'> | null
): number | undefined => {
  // Product variant is formatted like "10.00", price on shopify product is
  // formatted like "1000"
  const compareAtPrice = Number(shopifyProductVariant?.compareAtPrice || null);
  const regularPrice = Number(shopifyProductVariant?.price || null);
  const maxPrice = Number(shopifyProduct?.priceRange?.maxVariantPrice?.amount || null) / 100;

  const priceSourceMapping: {
    [key in OriginalPriceSource]: number;
  } = {
    [OriginalPriceSource.CompareAtPrice]: compareAtPrice,
    [OriginalPriceSource.RegularPrice]: regularPrice,
  };

  const priceList = compact(
    originalPriceSources.map((source: OriginalPriceSource) => priceSourceMapping[source])
  );

  return priceList.length > 0 ? Math.max(...priceList) : maxPrice;
};

const formatContextualPricing = (
  value: { amount: string; currencyCode: Currency } | undefined | null
) =>
  // Number(null) returns 0, but Number(undefined) returns NaN Price on shopify
  value && { amount: Number(value.amount || null), currencyCode: value.currencyCode };

export const getOriginalShopifyProductPrice = (
  brandCurrency: Currency, // Only used to format legacy product price.
  shopifyProduct?: Pick<Product, 'contextualPricing' | 'priceRange'>,
  shopifyProductVariant?: Pick<
    ShopifyProductVariant,
    'contextualPricing' | 'compareAtPrice' | 'price'
  > | null,
  originalPriceSources: OriginalPriceSource[] | null = [
    OriginalPriceSource.CompareAtPrice,
    OriginalPriceSource.RegularPrice,
  ]
): { amount: number; currencyCode?: Currency } | undefined | null => {
  // Default param value will handle undefined values, but need to explicitly handle null values
  // If it is null, that means nothing is selected in Contentful
  const originalPriceSourcesToUse = originalPriceSources ?? [];

  if (shopifyProduct?.contextualPricing || shopifyProductVariant?.contextualPricing) {
    const compareAtPrice = formatContextualPricing(
      shopifyProductVariant?.contextualPricing?.compareAtPrice
    );
    const regularPrice = formatContextualPricing(shopifyProductVariant?.contextualPricing?.price);
    const maxPrice = formatContextualPricing(
      shopifyProduct?.contextualPricing?.priceRange?.maxVariantPrice
    );

    const priceSourceMapping: {
      [key in OriginalPriceSource]: { amount: number; currencyCode?: Currency } | undefined | null;
    } = {
      [OriginalPriceSource.CompareAtPrice]: compareAtPrice,
      [OriginalPriceSource.RegularPrice]: regularPrice,
    };

    const priceList = originalPriceSourcesToUse.map(
      (source: OriginalPriceSource) => priceSourceMapping[source]
    );

    const compactPrices = compact(priceList) as { amount: number; currencyCode?: Currency }[];

    return compactPrices.length > 0
      ? compactPrices.reduce((maxValue, value) =>
          (maxValue?.amount || 0) > value.amount ? maxValue : value
        )
      : maxPrice;
  }

  const legacyPrice = getLegacyOriginalShopifyProductPrice(
    originalPriceSourcesToUse,
    shopifyProduct,
    shopifyProductVariant
  );
  return legacyPrice ? { amount: legacyPrice, currencyCode: brandCurrency } : undefined;
};

export const getShopifyProductVariantCompareAtAndPriceAverage = (
  shopifyProductVariant?: Pick<
    ShopifyProductVariant,
    'contextualPricing' | 'compareAtPrice' | 'price'
  > | null
): number | undefined => {
  const legacyPrice = shopifyProductVariant?.price;
  const legacyCompareAtPrice = shopifyProductVariant?.compareAtPrice;

  const contextualPrice = shopifyProductVariant?.contextualPricing?.price?.amount
    ? Number(shopifyProductVariant.contextualPricing.price.amount)
    : undefined;
  const contextualCompareAtPrice = shopifyProductVariant?.contextualPricing?.compareAtPrice?.amount
    ? Number(shopifyProductVariant.contextualPricing.compareAtPrice.amount)
    : undefined;

  if (contextualPrice && contextualCompareAtPrice) {
    return Number(((contextualPrice + contextualCompareAtPrice) / 2).toFixed(2));
  }

  if (legacyPrice && legacyCompareAtPrice) {
    return Number(((legacyPrice + legacyCompareAtPrice) / 2).toFixed(2));
  }

  return undefined;
};

export const shouldAutoApproveListing = (listing: STListingType) =>
  listing.attributes.publicData.listingItemType === ListingItemType.TradeIn ||
  (listing.attributes.publicData.isBrandDirect &&
    !listing.attributes.publicData.disableAutoApproval);

export const calculateListingPayoutUpdateFields = (
  keyToMatch: string,
  listing: STListingType,
  tradeInCreditPayoutOptions: ITradeInCreditPayoutOption[]
) => {
  const { originalPrice } = listing.attributes.publicData;

  const payoutOption =
    tradeInCreditPayoutOptions.find((option) => option.uniqueKeyOverride === keyToMatch) ||
    tradeInCreditPayoutOptions.find((option) => option.key === keyToMatch);

  if (!payoutOption) {
    throw new Error(
      `Item's key to match (${keyToMatch}) did not map to corresponding uniqueKeyOverride`
    );
  }

  const percentOfOriginalPrice =
    !isNil(payoutOption?.payoutPercentageOfOriginalPrice) && !isNil(originalPrice)
      ? // round to nearest cent
        Math.round(originalPrice * 100 * payoutOption!.payoutPercentageOfOriginalPrice)
      : undefined;

  return {
    listingId: listing.id.uuid,
    fullCreditAmount: listing.attributes.price.amount,
    creditPayoutPercentage: payoutOption?.payoutPercentage,
    creditPayoutFixedAmount: !isNil(payoutOption?.payoutFixedAmount)
      ? payoutOption!.payoutFixedAmount
      : percentOfOriginalPrice,
    tradeInCreditPayoutBundleAmount:
      listing.attributes.publicData?.payoutInfo?.tradeInCreditPayoutBundleAmount,
  };
};

export const mapAuthorIdToListingIds = (
  listings: ListingWithAuthorAndImages[]
): { [id: string]: string[] } =>
  listings.reduce((acc: { [id: string]: string[] }, listing: ListingWithAuthorAndImages) => {
    const authorId = listing.author.id.uuid;
    const listingId = listing.id.uuid;
    if (authorId in acc) {
      acc[authorId].push(listingId);
    } else {
      acc[authorId] = [listingId];
    }
    return acc;
  }, {});

export const mapAuthorIdToFulfillmentMethod = (
  authorIdToListingIds: { [id: string]: string[] },
  listings: ListingWithAuthorAndImages[],
  defaultBrandFulfillmentMethod: FulfillmentMethod
): { [id: string]: FulfillmentMethod } =>
  Object.keys(authorIdToListingIds).reduce((acc: { [id: string]: FulfillmentMethod }, authorId) => {
    const listingIds = authorIdToListingIds[authorId];
    const authorListings = listings.filter((listing) => listingIds.includes(listing.id.uuid));
    acc[authorId] = getFulfillmentMethod(authorListings, defaultBrandFulfillmentMethod);
    return acc;
  }, {});
export const convertSTListingToPGListing = (
  stListing: STListingType & { currentStock?: Record<string, any> }
): Partial<PGListingType> => {
  const { attributes } = stListing;
  const { publicData } = attributes;

  return {
    createdAt: attributes.createdAt,
    updatedAt: publicData.updatedAt,
    sharetribeListingId: stListing.id?.uuid,
    title: attributes.title,
    price: attributes.price?.amount,
    /// Sourced from Shopify Product Variant
    externalSku: publicData.shopifyProductVariantSku,
    availableInventory: stListing?.currentStock?.attributes?.quantity,
    state: attributes.state as Maybe<ListingState>,
    currency: attributes.price?.currency,
    shipFromCountry: publicData.shipFromCountry,
    itemBrand: publicData.itemBrand,
    sourceListingId: publicData.sourceListingId,
    isBrandDirect: publicData.isBrandDirect,
    itemType: publicData.itemType,
    sharetribePublicData: publicData,
    deletedAt: publicData.deletedAt,
    categories: publicData.categories,
    creditBoostPromoId: publicData.creditBoostPromoId,
    internalNotes: publicData.internalNotes,
    notes: publicData.notes,
    originalPrice: publicData.originalPrice,
    payoutInfo: publicData.payoutInfo,
    productHtml: publicData.productHtml,
    quirks: publicData.quirks,
    selectedVariantOptions: publicData.selectedVariantOptions,
    tags: publicData.tags,
    uploadId: publicData.uploadId,
    approvedAt: publicData.approvedAt,
    lastSoldAt: publicData.soldAt,
  };
};

export const convertPGListingToSTListing = (
  pgListing: Partial<PGListingType>
): Partial<STListingType> => {
  // if we don't have a full pgListing.sharetribePublicData reference, then
  // the pgListing update can be assumed to be the publicData update
  const { creditBoostPromoId, ...publicDataUpdate } = pgListing;
  const publicData = pgListing.sharetribePublicData || publicDataUpdate;
  const publicDataDifferentNames = {
    ...(pgListing.lastSoldAt && { soldAt: pgListing.lastSoldAt }),
    ...(creditBoostPromoId && { payoutCreditBoostPromoId: creditBoostPromoId }),
  };

  return {
    id: { uuid: pgListing.sharetribeListingId ?? '' },
    attributes: {
      title: pgListing.title ?? '',
      description: pgListing.sharetribePublicData?.shopName ?? '',
      publicData: { ...publicData, ...publicDataDifferentNames } ?? {},
      geolocation: null,
      price: {
        amount: pgListing.price ?? 0,
        currency: pgListing.currency ?? Currency.Usd,
      },
      createdAt: pgListing.createdAt,
      availabilityPlan: null,
      deleted: false,
      state: pgListing.state!,
    },
    type: 'listing',
  };
};
